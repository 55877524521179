// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/main')
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    all: path(ROOTS_DASHBOARD, '/blogs/all-blogs'),
    add: path(ROOTS_DASHBOARD, '/blogs/add-new-blog')
  },
  pages: {
    root: path(ROOTS_DASHBOARD, '/pages'),
    all: path(ROOTS_DASHBOARD, '/pages/all-pages'),
    add: path(ROOTS_DASHBOARD, '/pages/add-new-page')
  },
  places: {
    root: path(ROOTS_DASHBOARD, '/places'),
    all: path(ROOTS_DASHBOARD, '/places/all-places'),
    add: path(ROOTS_DASHBOARD, '/places/add-new-place')
  },
  cities: {
    root: path(ROOTS_DASHBOARD, '/cities'),
    all: path(ROOTS_DASHBOARD, '/cities/all-cities'),
    add: path(ROOTS_DASHBOARD, '/cities/add-new-city')
  },
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    all: path(ROOTS_DASHBOARD, '/clients/all-clients'),
    add: path(ROOTS_DASHBOARD, '/clients/add-new-client')
  },
  leads: {
    root: path(ROOTS_DASHBOARD, '/leads'),
    all: path(ROOTS_DASHBOARD, '/leads/all-leads')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    all: path(ROOTS_DASHBOARD, '/category/all-blogs-category')
  },
  tags: {
    root: path(ROOTS_DASHBOARD, '/tags'),
    all: path(ROOTS_DASHBOARD, '/tags/all-tags')
  },
  faqs: {
    root: path(ROOTS_DASHBOARD, '/faqs'),
    all: path(ROOTS_DASHBOARD, '/faqs/all-faqs')
  },
  terms: {
    root: path(ROOTS_DASHBOARD, '/terms'),
    all: path(ROOTS_DASHBOARD, '/terms/all-terms'),
    add: path(ROOTS_DASHBOARD, '/terms/add-new-term')
  },
  authors: {
    root: path(ROOTS_DASHBOARD, '/authors'),
    all: path(ROOTS_DASHBOARD, '/authors/all-authors')
  },
  countries: {
    root: path(ROOTS_DASHBOARD, '/countries'),
    all: path(ROOTS_DASHBOARD, '/countries/all-countries'),
    add: path(ROOTS_DASHBOARD, '/countries/add-new-country')
  },
  provinces: {
    root: path(ROOTS_DASHBOARD, '/provinces'),
    all: path(ROOTS_DASHBOARD, '/provinces/all-provinces'),
    add: path(ROOTS_DASHBOARD, '/provinces/add-new-province')
  },
  config: {
    root: path(ROOTS_DASHBOARD, '/config'),
    all: path(ROOTS_DASHBOARD, '/config/complete-config')
  }
};
