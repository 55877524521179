// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard }]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // commented to hide user menu in sidebar
  {
    subheader: 'Manage Places',
    items: [
      {
        title: 'Places',
        path: PATH_DASHBOARD.places.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Places', path: PATH_DASHBOARD.places.all },
          { title: 'Add New Place', path: PATH_DASHBOARD.places.add }
        ]
      }
    ]
  },
  {
    subheader: 'Manage Cities',
    items: [
      {
        title: 'Cities',
        path: PATH_DASHBOARD.cities.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Cities', path: PATH_DASHBOARD.cities.all },
          { title: 'Add New City', path: PATH_DASHBOARD.cities.add }
        ]
      }
    ]
  },
  {
    subheader: 'Provinces/State',
    items: [{ title: 'Manage States', path: PATH_DASHBOARD.provinces.all, icon: ICONS.user }]
  },
  {
    subheader: 'Countries',
    items: [{ title: 'Manage Countries', path: PATH_DASHBOARD.countries.all, icon: ICONS.user }]
  },

  {
    subheader: 'Articles',
    items: [
      {
        title: 'Articles (Blogs)',
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Blogs', path: PATH_DASHBOARD.blogs.all },
          { title: 'Add New Blog', path: PATH_DASHBOARD.blogs.add }
        ]
      }
    ]
  },
  {
    subheader: ' article Tags',
    items: [{ title: 'Manage Tags', path: PATH_DASHBOARD.tags.all, icon: ICONS.banking }]
  },
  {
    subheader: 'article Category',
    items: [{ title: 'Manage Category', path: PATH_DASHBOARD.category.all, icon: ICONS.user }]
  },

  {
    subheader: 'Static',
    items: [
      {
        title: 'Static Pages',
        path: PATH_DASHBOARD.pages.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Pages', path: PATH_DASHBOARD.pages.all },
          { title: 'Add New Page', path: PATH_DASHBOARD.pages.add }
        ]
      }
    ]
  },
  {
    subheader: 'Authors',
    items: [{ title: 'Manage Authors', path: PATH_DASHBOARD.authors.all, icon: ICONS.banking }]
  },
  {
    subheader: 'Business Leads',
    items: [{ title: 'Manage Leads', path: PATH_DASHBOARD.leads.all, icon: ICONS.user }]
  }
];

export default sidebarConfig;
